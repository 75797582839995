export const injectJavascript = ({ id = 'javascriptCustom', content = '', parent }) => {
  let head = parent || document.head || document.getElementsByTagName('head')[0]
  let javascriptCustom = document.getElementById(id)
  if (javascriptCustom) {
    javascriptCustom.remove()
  }
  let script = document.createElement('script')
  script.setAttribute('id', id)
  script.type = 'text/javascript'
  script.appendChild(document.createTextNode(content))
  head.appendChild(script)
}

export const injectStyle = ({ id = 'stylesCustom', content = '', parent }) => {
  let head = parent || document.head || document.getElementsByTagName('head')[0]
  let stylesCustom = (parent || document).getElementById ? (parent || document).getElementById(id) : false

  if (stylesCustom) {
    stylesCustom.innerText = content
  } else {
    let style = document.createElement('style')
    style.setAttribute('id', id)
    style['type'] = 'text/css'
    if (style['styleSheet']) {
      // This is required for IE8 and below.
      style['styleSheet']['cssText'] = content
    } else {
      style.appendChild(document.createTextNode(content))
    }
    head.appendChild(style)
  }
}
