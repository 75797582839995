<template>
  <div class="page">
    <vue-topprogress ref="topProgress"></vue-topprogress>
    <transition-group id="components" name="component" tag="div" class="component_list" :class="[id, viewClass]">
      <ObjectLoader :position="index" :plkparams="component" :instance="getInstance(component.type)" v-for="(component, index) in componentsWithInstance" :key="`${Math.random()}-${index}`"/>
    </transition-group>
    <div v-if="showMarkwater" class="watermark">Plaka Studio <i class="plk icon-plk">&#xe805;</i> Design Mode</div>
  </div>
</template>

<script>
import Vue from 'vue'
import { executeActions } from './commons/actions'
import ObjectLoader from '@/views/viewer/objectLoaders/design/ObjectLoader'
import { LOG_SUCCESS, LOG_ERROR } from '@/services'
import { Store, DESIGN } from '@/store'
import { vueTopprogress } from 'vue-top-progress'
import { injectStyle, injectJavascript } from '@/plugins/injections'
import { APP_STYLES_CONFIG_FILE, APP_COLORS_CONFIG_FILE, APP_JAVASCRIPT_CONFIG_FILE, IS_DESIGN_MODE } from '@/enviroment/app'

export default {
  name: 'designviewer',
  components: {
    vueTopprogress,
    ObjectLoader
  },
  data () {
    return {
      instances: {},
      view: {},
      id: this.$route.params.id || 'index',
      executingActions: true,
      showMarkwater: IS_DESIGN_MODE
    }
  },
  metaInfo () {
    return this.view.metaInfo || {}
  },
  beforeDestroy () {
    this.$servicesPLK.event.emitEvent(this.$servicesPLK.event.BASIC_ACTIONS.DESTROY, {})
  },
  computed: {
    components () {
      return this.view.components || []
    },
    viewClass () {
      return this.view.viewClass || ''
    },
    componentsWithInstance () {
      return this.executingActions ? [] : this.components.filter(object => this.getInstance(object.type))
    },
    javascript () {
      return (Store.getters[DESIGN.GETTERS.GET_FILE_CONTENT](APP_JAVASCRIPT_CONFIG_FILE) || {}).data || ''
    },
    style () {
      return (Store.getters[DESIGN.GETTERS.GET_FILE_CONTENT](APP_COLORS_CONFIG_FILE) || {}).data || ''
    },
    colors () {
      return (Store.getters[DESIGN.GETTERS.GET_FILE_CONTENT](APP_STYLES_CONFIG_FILE) || {}).data || ''
    }
  },
  watch: {
    components () {
      this.loadComponents()
    },
    $route (to, from) {
      window.scrollTo(0, 0)
      Vue.set(this, 'id', to.params.id)
      this.$servicesPLK.event.emitEvent(this.$servicesPLK.event.BASIC_ACTIONS.DESTROY, {})
      this.onInitComponent()
    }
  },
  methods: {
    onInitComponent () {
      this.loadView()
      injectJavascript({ content: this.javascript })
      injectStyle({ id: 'colorsCustom', content: this.colors })
      injectStyle({ id: 'styleCustom', content: this.style })
      this.$servicesPLK.event.onEvent(this.$servicesPLK.event.BASIC_ACTIONS.NETWORK_ERROR, this.watchErrors)
    },
    watchErrors ({ object }) {
      if (object.code === 'ERR_401') {
        window.parent.location.reload()
      }
    },
    loadView () {
      this.$refs.topProgress.start()
      Vue.set(this, 'executingActions', true)
      this.$servicesPLK.component.getView(this.id).then(async (view) => {
        this.$servicesPLK.logger.log(this.id, `${this.id}-loadPage-200`, LOG_SUCCESS)
        Vue.set(this, 'view', (view || {}))
        await executeActions(view.actions)
        Vue.set(this, 'executingActions', false)
        this.$refs.topProgress.done()
      }).catch(() => {
        this.$refs.topProgress.fail()
        this.$servicesPLK.logger.log(this.id, `${this.id}-loadPage-404`, LOG_ERROR)
        this.$router.push({ path: '/' })
      })
    },
    getInstance (type) {
      return this.instances[this.normalizeType(type)]
    },
    normalizeType (type) {
      return this.$servicesPLK.component.normalizeType(type)
    },
    loadComponents () {
      this.components.forEach((object) => {
        if (!object.type) {
          console.warn('No found type object')
        } else if (object.type && !this.instances[object.type]) {
          this.$servicesPLK.component.loadComponent(object.type, this.instances, this.normalizeType(object.type))
        }
      })
    }
  }
}
</script>
<style scoped>
  .component_list {
    height: 100vh;
  }
  .watermark {
    position: fixed;
    bottom: 0;
    padding: 10px 0px;
    background: #efefef66;
    text-align: center;
    right: 0;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    height: 100vh;
    font-size: 1.5em;
    .icon-plk {
      transform: rotate(90deg);
    }
  }
</style>

<style scoped lang="scss">
  /* &-leave-active in <2.1.8 */
  /* &-enter { opacity: 0 } */
  /* &-enter { transform: scale(0.95) } */
  .component {
    &-enter-active, &-leave-active {
      transition: opacity 1s;
    }

    &-enter {
      opacity: 0;
    }

    &-leave-to {
      opacity: 0;
      display: none;
    }
  }
</style>
